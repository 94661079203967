import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Portfolio = () => (
  <Layout>
    <Seo title="Portfolio" />
    <div className="portfolio-header-section">
      <div>
      <h1 className="portfolio-header portfolio-header-text-glow">Our Work</h1>
      <a 
        className="app-header blipp-text-glow"
        href="https://apps.apple.com/us/app/blipp/id1056166065"
        style={{color: '#fbf590',}}>
          <h2 className="app-header">blipp.</h2>
        </a>
      <h5 className="portfolio-header-disclaimer portfolio-header-text-glow">Blipp is a social media app<br></br>that allows you to interact<br></br>with people around you<br></br>and all over the world.</h5>
      </div>
      <div className="portfolio-image-container">
        <a className="portfolio-image" href="https://apps.apple.com/us/app/blipp/id1056166065">
          <StaticImage
            src="../images/blipp-portfolio.png"
            quality={100}
            width={550}
            formats={["auto", "webp", "avif"]}
            alt="blipp application logo"
            style={{ marginBottom: `5rem` }}
          />
        </a>
      </div>
    </div>
    <div className="portfolio-header-body">
      <div>
      <h3 className="portfolio-callout app-card-title-glow">Take Photos and Videos</h3>
      <h4 className="portfolio-body text-glow">
        Share them<br />
        with friends<br />
        across the world!<br />
      </h4>
      </div>
    </div>
    <div className="portfolio-header-section-2">
      <div className="portfolio-image-container-2">
        <StaticImage
          src="../images/your-albums-portfolio.png"
          quality={100}
          width={600}
          formats={["auto", "webp", "avif"]}
          alt="your albums application logo"
          style={{ marginBottom: `5rem` }}
        />
      </div>
      <div>
        <a href="https://apps.apple.com/us/app/your-albums/id1541399701">
          <h2 className="app-header albums-text-glow" style={{color: '#f92c47', marginBottom: '20px'}}>Your<br />Albums</h2>
        </a>
        
        <h5 className="portfolio-header-disclaimer portfolio-header-text-glow">
          Your Albums is a music <br />
          app
          that lets you add<br />
          music to your iPhone’s<br />
          home screen.</h5>
      </div>
    </div>
    <div className="portfolio-header-body" style={{textAlign: 'right', marginBottom: '0px'}}>
      <div>
        <h3 className="portfolio-callout app-card-title-glow">Build your vibe. </h3>
        <h4 className="portfolio-body text-glow">
          Play your <br />
          favorite music <br />
          quickly.
        </h4>
      </div>
    </div>
  </Layout>
)

export default Portfolio
